import React, {FC} from 'react';

const TermsOfUsePage:FC = () => {
    return (
        <div>
            Terms Of Use
        </div>
    );
};

export default TermsOfUsePage;
