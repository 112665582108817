import React from 'react';
import style from './Tabs.module.css'
import DataEngeneer from "./DataEngineer";
import Management from "./Management";
import Researcher from "./Researcher";

const TabContent = ({ title, content }) => (
    <div className={style.tabContent}>
        <p>{content}</p>
    </div>
);



const Tabs = () => {
    const [ active, setActive ] = React.useState(0);

    const items = [
        { title: 'Data Engineer', content: <DataEngeneer/> },
        { title: 'Researcher', content: <Researcher/> },
        { title: 'Management', content: <Management/> }
    ];

    const openTab = e => setActive(+e.target.dataset.index);

    return (
        <div>
            <div className="tab">
                {items.map((n, i) => (
                    <button
                        className={`tablinks ${i === active ? style.activeTab : style.defaultTab}`}
                        onClick={openTab}
                        data-index={i}
                    >{n.title}</button>
                ))}
            </div>
            {items[active] && <TabContent {...items[active]} />}
        </div>)
}


export default Tabs;
