import React from 'react';
import {useRoutes} from "./Routes/routes";

function App() {

    const routes = useRoutes()

    return (
        <div className="App">
            {routes}
        </div>
    );
}

export default App;
