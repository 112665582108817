import MainPagePage from "../Pages/MainPage";
import PrivacyPolicyPage from "../Pages/PrivacyPolicy";
import TermsOfUsePage from "../Pages/TermsOfUse";


export const publicRoutes = [
    {path: '/', element: MainPagePage},
    {path: '/privacy-policy', element: PrivacyPolicyPage},
    {path: '/terms-of-use', element: TermsOfUsePage},
]
