import React, {FC} from 'react';
import style from './SideNavigation.module.css'
import {Link} from "react-scroll";
import logo from './../../assets/img/logo.svg'

type OwnToProps = {
    darkMode: boolean
    logoActive: boolean
    stickyMode: boolean
}

const SideNavigation:FC<OwnToProps> = ({darkMode, logoActive,stickyMode}) => {

    const localRoutes: string[] = ['Modelyst','What we do', 'About us', 'Case studies', 'FAQ', 'Contact us']

    return (
        <div className={!stickyMode ? style.sideMenu : style.sideMenuMain}>
            {logoActive && <Link to="Main block" activeClass="activeLogo" smooth spy duration={300}><img className={style.logo} src={logo} alt="logo"/></Link>}
            <ul>
                {localRoutes.map((el, index) => <li key={index}><Link activeClass={style.activeLink} style={{color: '#ABA2BE'}} className={!darkMode ? style.link : style.darkLink} smooth spy duration={300} to={el}>{el}</Link></li>)}
            </ul>
        </div>
    );
};

export default SideNavigation;
