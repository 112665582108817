import React, {FC} from 'react';
import Faq from 'react-faq-component';
import plus from './../../../assets/img/plus.svg'
import minus from './../../../assets/img/minus.svg'

const data = {
    rows: [
        {
            title: "Why should I not build a similar platform in-house?",
            content: "Hiring several data engineers for to each R&D team is very costly. We have already implemented the baseline infrastructure code that is common to almost all use cases, and our software is set up so that we can quickly implement the customizations that your team needs. Why pay engineers to do years of infrastructure work that we have already done?"
        },
        {
            title: "What kind of customer support do you have?",
            content: "Our service is white-glove: customer support is all we sell. We are always available if you are having difficulty. If there is an urgent need for a new solution, we are available to begin implementing it within 2 business days. We are available during business hours and respond to emails regarding issues you are having within 8 business hours, and the resolution time depends on the complexity of the request."
        },
        {
            title: "In terms of cost, how do you differ from other companies?",
            content: "Any company's service that is cheaper than ours is not as customized as ours. If you want to try to adapt your workflows to someone else's software, that will be cheaper, but if you want a solution implemented for you that is specifically designed for your needs, we are the cheapest option."
        },
        {
            title: "What kind of data sources do we use/need? ",
            content: "We can work with any data that is consistently formatted. We assist with cleaning data and loading it into a database. Your files need not be a standard file type - we have already written plenty of custom parsers for customer-specific file types."
        },
        {
            title: "If we engage with you, how much of my time do you need? Will I spend more time working with you than it would take me to do the job myself?",
            content: "We typically meet with our clients for one hour per week and work for 16 hours per week independently, so the time you spend with us will be amplified more than ten-fold."
        },
        {
            title: "If we engage Modelyst, what is my role in the project?",
            content: "The role of the data engineer is to provide input on the schema design and overall direction of the project. You get to keep the fun part of the job, and we do the dirty work of implementing the data pipeline that the team agrees is best."
        }]
}


const DataEngeneer: FC = () => {
    return (
        <Faq
            data={data}
            config={{
                // arrowIcon: <img src={plus} alt="plus"/>,
                animate: true,
                openOnload: 0,
                expandIcon: <img src={plus} alt="plus"/>,
                collapseIcon: <img src={minus} alt="minus"/>,
                // collapseIcon: "-"
            }}
        />)
};

export default DataEngeneer;
