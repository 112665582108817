import React, { FC } from "react";
import style from "./AboutUs.module.css";
import Quote from "../../../Components/Quote";
import avatar from "./../../../assets/img/ceo-avatar.png";
import OurStorySnippet from "../../../Components/OurStoryComponent";
import { Row } from "react-bootstrap";
import { peopleCard } from "../../../InputData";
import PeopleCard from "../../../Components/PeopleCard";
import { useMediaQuery } from "../../../Hooks/mediaPhone";
import { AnimationOnScroll } from "react-animation-on-scroll/dist/js/components";

const AboutUs: FC = () => {
  const isPagedWide = useMediaQuery("(max-width: 926px)");

  return (
    <section id="About us" className={style.aboutUs}>
      {/*<AnimationOnScroll animateIn={'animate__fadeInUp'} animateOnce duration={1.4}>*/}
      {/*<div className={style.ourVision}>*/}
      {/*    {isPagedWide && <h2 className={style.mobileName}>About us</h2>}*/}
      {/*    <h2>Our Vision</h2>*/}
      {/*    <p>As civilization has progressed, significant problems in climate, health, and other fields have*/}
      {/*        arisen. Across the world, scientists are working on solutions, and to do that more efficiently, they*/}
      {/*        are asking more questions of their data and using new computational analyses more than ever before.*/}
      {/*        Our goal is to help organizations ensure that their scientific data is used as effectively as*/}
      {/*        possible and that so that solutions to these problems can be found quickly.</p>*/}
      {/*</div>*/}
      {/*</AnimationOnScroll>*/}
      {/*<AnimationOnScroll animateIn={'animate__fadeInUp'} animateOnce duration={1.4}>*/}
      {/*<Quote lightMode={true} avatar={avatar} name="Brian Rohr" role="CEO, Modelyst"*/}
      {/*       description="“We are determined to accelerate scientific progress and help industry solve global problems more efficiently.”"/>*/}
      {/*</AnimationOnScroll>*/}
      <AnimationOnScroll
        animateIn={"animate__slideInUp"}
        animateOnce
        duration={0.6}
        delay={0.15}
      >
        <div className={style.people}>
          <Row>
            {peopleCard.map((el) => (
              <PeopleCard
                key={el.id}
                avatar={el.avatar}
                name={el.name}
                description={el.description}
              />
            ))}
          </Row>
        </div>
      </AnimationOnScroll>
      <AnimationOnScroll
        animateIn={"animate__fadeInUp"}
        animateOnce
        duration={1.4}
      >
        <div className={style.ourStory}>
          <h2>Our Story</h2>
          <OurStorySnippet />
        </div>
      </AnimationOnScroll>
    </section>
  );
};

export default AboutUs;
