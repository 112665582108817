import React from 'react';
import {Route, Routes, useLocation} from "react-router-dom";
import {publicRoutes} from "./indexRoutes";
import MainPage from "../Pages/MainPage";
import Example from "../Components/Modal";

export const useRoutes = () => {
    const location = useLocation()
    // @ts-ignore
    const background = location.state && location.state.background
    return (
        <>
            <Routes>
                <Route path="/" element={<MainPage/>}>
                    <Route path="case/:id" element={<Example/>}/>
                </Route>
                {publicRoutes.map(el => <Route key={el.path} path={el.path} element={<el.element/>}/>)}
            </Routes>
            {background && (
                <Routes>
                    <Route path="case/:id" element={<Example/>}/>
                </Routes>
            )}

        </>
    );
};

