import React, { FC } from "react";
import style from "./ContactUs.module.css";
import ContactForm from "../../../Components/ContactForm";
import { useMediaQuery } from "../../../Hooks/mediaPhone";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Form from "../../../Components/ContactForm/Form";

const ContactUs: FC = () => {
  const isPagedWide = useMediaQuery("(max-width: 926px)");

  return (
    <section id="Contact us" className={style.contactUs}>
      <AnimationOnScroll
        animateIn={"animate__fadeInUp"}
        animateOnce
        duration={1.4}
      >
        <AnimationOnScroll
          animateIn={"animate__fadeInUp"}
          animateOnce
          duration={1.4}
        >
          {isPagedWide && <h2 className={style.mobileName}>Contact us</h2>}
          {/*<h2>Answer the questions to see if Modelyst can help you</h2>*/}
          <h2>Contact Us</h2>
          <p>
            If you have any questions or would like to talk to us about working
            together, please write us a message below
          </p>
          {/*<p>Or, if you already know what you want <a href="#">Contact Us</a></p>*/}
        </AnimationOnScroll>
        {/*<ContactForm/>*/}
        <Form />
      </AnimationOnScroll>
    </section>
  );
};

export default ContactUs;
