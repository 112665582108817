import React, {FC} from 'react';
import style from './PastClientBlockItem.module.css'
import {Col} from "react-bootstrap";
import { AnimationOnScroll } from 'react-animation-on-scroll';

type OwnToProps = {
    img: string
    name: string
}

const PastClientBlockItem: FC<OwnToProps> = ({img, name}) => {
    return (
        <Col xs={12} md={4} lg={4}>
            <div className={style.clientBlock}>
                <img src={img} alt="img"/>
                <p>{name}</p>
            </div>
        </Col>
    );
};

export default PastClientBlockItem;
