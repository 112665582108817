import {FC} from 'react';
import style from './mainPage.module.css'
import MainBlock from "./MainBlock";
import ModelystBlock from "./ModelystBlock";
import SideNavigation from "../../Components/SideNavigation";
import WhatWeDoBlock from "./WhatWeDoBlock";
import AboutUs from "./AboutUs";
import CaseStudiosBlock from "./CaseStudiosBlock";
import FAQBlock from "./FAQ";
import ContactUs from "./ContactUs";
import Footer from "../../Components/Footer";

const MainPagePage: FC = () => {
    return (
        <div className={style.App}>
            <MainBlock/>
            {/*<div className={style.infoBlock}>*/}
            {/*    <SideNavigation darkMode={false} logoActive={true} stickyMode={true}/>*/}
            {/*    <ModelystBlock/>*/}
            {/*</div>*/}
            {/*<div className={style.infoBlockDark}>*/}
            {/*    <SideNavigation darkMode={true} logoActive={false} stickyMode={false}/>*/}
            {/*    <WhatWeDoBlock/>*/}
            {/*</div>*/}
            <div className={style.infoBlock + ' ' + style.infoBlockAfter}>
                <SideNavigation darkMode={false} logoActive={true} stickyMode={true}/>
                <div>
                    <ModelystBlock/>
                    <WhatWeDoBlock/>
                    <AboutUs/>
                    <CaseStudiosBlock/>
                    <FAQBlock/>
                    <ContactUs/>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default MainPagePage;
