import React, {FC} from 'react';
import plus from "../../../assets/img/plus.svg";
import Faq from "react-faq-component";


const data = {
    rows: [
        {
            title: "What kind of customer support do you have?",
            content: "Our service is white-glove: customer support is all we sell. We are always available if you are having difficulty. If there is an urgent need for a new solution, we are available to begin implementing it within 2 business days. We are available during business hours and respond to emails regarding issues you are having within 8 business hours, and the resolution time depends on the complexity of the request. (I have heard in multiple discussions in my life and I strongly think this is an important factor in making a decision. So we must have an equally strong answer/system for this)"
        },
        {
            title: "What kind of data sources do we use/need?",
            content: "We can work with any data that is consistently formatted. We assist with cleaning data and loading it into a database. Your files can be but do not need to be in a standard file type (csv, json, yaml, etc.) - we have already written plenty of custom parsers for customer-specific file types."
        },
        {
            title: "How do you ensure our data is secure?",
            content: "We will make sure that your data is secure. Typically, we use Amazon Web Services (AWS) and restrict access using RSA key pairs, strong passwords, and IP address whitelisting. If a different cloud provider or an on-premises implementation is required, we can support that as well."
        },
        {
            title: "Why do I want my data in a database?",
            content: "Databases enable researchers to ask questions of their data using a single query that would otherwise require scripts to answer."
        },
        {
            title: "If we engage with you, how much of my time do you need? Will I spend more time working with you than it would take me to do the job myself?",
            content: "You will spend an hour or two a week with us, and we will work offline for ~16 hours/week. You will absolutely spend much less time working with us than doing the work yourself."
        },
        {
            title: "Will Modelyst help me figure out the right data model (schema)?",
            content: "Yes, and we will not force you to use a specific schema! We will first sit down with you and understand what types of data you capture and what types of questions you want to be able to ask and answer. Then, we will decide together on an appropriate data model, leveraging our expertise and past experience as well as your domain knowledge and experience with your own data."
        }]
}

const Researcher:FC = () => {
    return (
        <Faq
            data={data}
            config={{
                arrowIcon: <img src={plus} alt="plus"/>,
            }}
        />
    );
};

export default Researcher;
