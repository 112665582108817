import React, {FC, useEffect, useState} from 'react';
import style from './CaseStudiosItemBlock.module.css'
import {Col, Row} from "react-bootstrap";
import vector from './../../assets/img/vector.svg'
import Example from "../Modal";
import {Link, Outlet, useLocation} from "react-router-dom";
import {localDataCase} from "../../Types";

type OwnToProps = {
    id: number
    img: string
    name: string
    data?: localDataCase
}

const CaseStudiosItemBlock: FC<OwnToProps> = ({img, name, id, data}) => {

    const [show, setShow] = useState(false);

    const location = useLocation()


    useEffect(() => {
        if (location.pathname === `/case/${id}`) {
            setShow(true);
        }
    }, [location, id]);

    return (
        <Col xs={12} lg={12} md={12}>
            <div className={style.caseItem}>
                <Row>
                    <Col xs={12} lg={2} md={2}><img src={img} alt="img"/></Col>
                    <Col xs={12} lg={10} md={10}><h3>{name}</h3></Col>
                </Row>
                <div className={style.caseItemBtn}>
                    <Example show={show} setShow={setShow} data={data} name={name}>
                        <Link onClick={() => setShow(true)} to={`/case/${id}`} state={{background: location}}>View Case
                            study<img src={vector}
                                      alt="vector"/></Link>
                    </Example>
                    <Outlet/>
                </div>
            </div>
        </Col>
    );
};

export default CaseStudiosItemBlock;
