import React, { FC } from "react";
import style from "./WhatWeDoBlock.module.css";
import {
  modelystHelpAutomation,
  modelystHelpMachineLearning,
  modelystHelpUserInterfaces,
  pastClient,
} from "../../../InputData";
import PastClientBlockItem from "../../../Components/PastClientBlockItem";
import { Row } from "react-bootstrap";
import avatar from "./../../../assets/img/ceo-avatar.png";
import Quote from "../../../Components/Quote";
import { useMediaQuery } from "../../../Hooks/mediaPhone";
import { AnimationOnScroll } from "react-animation-on-scroll";

const WhatWeDoBlock: FC = () => {
  const isPagedWide = useMediaQuery("(max-width: 926px)");

  return (
    <section id="What we do" className={style.whatWeDoBlock}>
      {/*<AnimationOnScroll animateIn={'animate__fadeInUp'} animateOnce duration={1.4}>*/}
      {/*    <div className={style.data}>*/}
      {/*        {isPagedWide && <h2 className={style.mobileName}>What we do</h2>}*/}
      {/*        <h2>Custom data infrastructure designed for your team</h2>*/}
      {/*        <p>Modelyst implements custom data infrastructure software for our clients. We usually start by*/}
      {/*            automatically ingesting data into one central, accessible, shareable, secure database, and what*/}
      {/*            happens next depends on the client's specific needs.</p>*/}
      {/*    </div>*/}
      {/*</AnimationOnScroll>*/}
      <AnimationOnScroll
        animateIn={"animate__fadeInUp"}
        animateOnce
        duration={1.4}
      >
        <div className={style.work}>
          <h2>How it works</h2>
          <p>
            Modelyst uses a consulting model; we meet with your team weekly and
            implement software to address your most pressing needs. Many clients
            choose to continue renewing contracts with us, and we grow to feel
            like part of the team.
          </p>
          <p>
            We usually start by automatically ingesting data into one central,
            accessible, shareable, secure database, and what happens next
            depends on the client's specific needs.
          </p>
        </div>
      </AnimationOnScroll>
      <AnimationOnScroll
        animateIn={"animate__fadeInUp"}
        animateOnce
        duration={1.4}
      >
        <div className={style.client}>
          <h2>With Modelyst's help:</h2>
          <div className={style.automation}>
            <h3>Automation</h3>
            <Row>
              {modelystHelpAutomation.map((el) => (
                <PastClientBlockItem
                  key={el.id}
                  img={el.imgLight}
                  name={el.name}
                />
              ))}
            </Row>
          </div>
          <div className={style.machine}>
            <h3>Machine Learning Support</h3>
            <Row>
              {modelystHelpMachineLearning.map((el) => (
                <PastClientBlockItem
                  key={el.id}
                  img={el.imgLight}
                  name={el.name}
                />
              ))}
            </Row>
          </div>
          <div className={style.userInterfaces}>
            <h3>User Interfaces</h3>
            <Row>
              {modelystHelpUserInterfaces.map((el) => (
                <PastClientBlockItem
                  key={el.id}
                  img={el.imgLight}
                  name={el.name}
                />
              ))}
            </Row>
          </div>
          {/*<Row>*/}
          {/*    {pastClient.map(el => <PastClientBlockItem key={el.id} img={el.imgLight}*/}
          {/*                                               name={el.name}/>)}*/}
          {/*</Row>*/}
        </div>
      </AnimationOnScroll>
      <AnimationOnScroll
        animateIn={"animate__fadeInUp"}
        animateOnce
        duration={1.4}
      >
        <Quote
          lightMode={true}
          avatar={avatar}
          name="Brian Rohr"
          role="CEO, Modelyst"
          description="“If your R&D team has raw scientific data, and what it needs is organized data sets containing the high-level results and performance metrics that your R&D teams truly care about, Modelyst is the perfect partner.”"
        />
      </AnimationOnScroll>
      {/*<AnimationOnScroll animateIn={'animate__fadeInUp'} animateOnce duration={1.4}>*/}
      {/*    <div className={style.models}>*/}
      {/*        <h2>Engagement Models</h2>*/}
      {/*        <Row>*/}
      {/*            {engagementModels.map(el => <EngagementModelsItem key={el.id}*/}
      {/*                                                              name={el.name}*/}
      {/*                                                              description={el.description}/>)}*/}
      {/*        </Row>*/}
      {/*        <div className={style.modelsBtn}>*/}
      {/*            <a href="#">Sign up for the free platform<img src={lightVector}*/}
      {/*                                                          alt="btn"/></a>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*</AnimationOnScroll>*/}
      {/*<AnimationOnScroll animateIn={'animate__fadeInUp'} animateOnce duration={1.4}>*/}
      {/*    <div className={style.learn}>*/}
      {/*        <Row>*/}
      {/*            <Col xs={12} md={1} lg={1}>*/}
      {/*                <img src={lamp} alt="img"/>*/}
      {/*            </Col>*/}
      {/*            <Col xs={12} md={11} lg={11}>*/}
      {/*                <p>Learn how Modelyst was able to help reduce 75% costs for a customer</p>*/}
      {/*                <div className={style.learnBtn}>*/}
      {/*                    <a href="#">View Case study<img src={lightVector}*/}
      {/*                                                    alt="btn"/></a>*/}
      {/*                </div>*/}
      {/*            </Col>*/}
      {/*        </Row>*/}
      {/*    </div>*/}
      {/*</AnimationOnScroll>*/}
    </section>
  );
};

export default WhatWeDoBlock;
