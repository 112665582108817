import React from 'react';
import {Timeline, Event} from "react-timeline-scribble";
import {ourStory} from "../../InputData";
import { AnimationOnScroll } from 'react-animation-on-scroll/dist/js/components';

const OurStorySnippet = () => {
    return (
        <Timeline>
            {ourStory.map(el => <AnimationOnScroll animateIn={'animate__slideInUp'} animateOnce duration={0.3} delay={0.15}><Event key={el.id} interval={el.date}>{el.description}</Event></AnimationOnScroll>)}
        </Timeline>
    );
};

export default OurStorySnippet;
