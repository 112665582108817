import React, {FC} from 'react';
import plus from "../../../assets/img/plus.svg";
import Faq from "react-faq-component";

const data = {
    rows: [
        {
            title: "What are the different ways we can engage?",
            content: "We offer a service model. Think of us as part-time, pay-per-use members of your team. We offer a limited hours of consultancy per month and work a few days per month on your project against a modest fee.\n" +
                "\n" +
                "We also offer a model where we offer consulting for a limited period to get initial data pipelines set up, and then your organization will be able to be self-sufficient, and we are always available if you need more, new data pipelines set up. \n"
        },
        {
            title: "Why will you be more cost efficient than hiring more data engineers?",
            content: " Part of the work you need done is specific to you, and part of it is the same for every scientific data engineering project. We have already done the part that is common to all projects, so we can implement data pipelines extremely quickly and efficiently. There is no reason to spend months hiring someone to wait months or even years for them to build the infrastructure that we have already built.\n" +
                "\n" +
                "Furthermore, your data engineering needs may fluctuate from time to time. What if you get a new stream of data coming online? There will be a spike in data engineering work that needs to be done. You can scale our level of engagement up and down freely, as needed. Keep your fixed burn low!\n"
        },
        {
            title: "How much does it cost?",
            content: "Our rates are commensurate with the industry standard for custom software development. To get a given project done, hiring us is less expensive, lower-risk, and requires less of your time compared to hiring one or many full-time data engineers."
        },
        {
            title: "How do you differentiate yourselves - from all these companies approaching us every other day?",
            content: "Nobody else is offering the white-glove service that we do. Everyone else who comes into your office with a data management solution is asking you to fit into their system at least to some degree. We are offering to make a system that is designed for and works perfectly for your team."
        },
        {
            title: "In terms of cost, how do you differ from other companies?",
            content: "Any company’s service that is cheaper than ours is not as customized as ours. If you want to try to fit your world into someone else’s schema, that will be cheaper, but if you want a solution implemented for you that makes sense for you, we are the cheapest option."
        },
        {
            title: "How long will it take to show a Return on Investment (ROI) on you?",
            content: "Data scientists spend 50-80% of their time on data wrangling. If we increase even two data scientists' efficiency by even 25%, your investment will be returned within our first year of working together.If you are trying to optimize a material property or a chemical process without using modern Bayesian optimization techniques, you are missing out on very large potential gain in efficiency. Working with us to help you attain these gains would have a very large ROI."
        },
        {
            title: "Can we do a short-term project to see if we like this engagement model?",
            content: "Yes! Let’s sit down and have two one-hour meetings together for free, and then get started with a 3-month trial contract. Hiring a data engineer is a much larger commitment."
        }
    ]
}

const Management: FC = () => {
    return (
        <Faq
            data={data}
            config={{
                arrowIcon: <img src={plus} alt="plus"/>,
            }}
        />
    );
};

export default Management;
