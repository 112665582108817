import { FC, useRef } from "react";
import HeaderNavbar from "../../../Components/HeaderNavBar";
import style from "./MainBlock.module.css";
import { Link } from "react-scroll";
import mouseClick from "./../../../assets/img/mouse-click.svg";
import { useMediaQuery } from "../../../Hooks/mediaPhone";
import MobileMenu from "../../../Components/MobileMenu";
import Particles from "./Particles";


const MainBlock: FC = () => {
  const isPagedWide = useMediaQuery("(max-width: 926px)");
  const particlesContainer = useRef(null);

  return (
    <section ref={particlesContainer} id="Main block" className={style.mainBlock}>
      <div className={style.particlesContainer}>
        <Particles />
      </div>
      {isPagedWide ? <MobileMenu /> : <HeaderNavbar />}
      <h1>
        Let’s Build Your Research
        <br />
        Data Infrastructure
      </h1>
      
      <Link
        to="Modelyst"
        activeClass="scrollActive"
        smooth
        spy
        className={style.scrollDown}
      >
        <img className={style.shakeVertical} src={mouseClick} alt="click" />
        <span>Scroll down</span>
      </Link>
    </section>
  );
};

export default MainBlock;
