import React, {FC, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import style from './Modal.module.css'
import {Link, useNavigate} from "react-router-dom";
import {localDataCase} from "../../Types";
import {Col, Row} from "react-bootstrap";
import vector from './../../assets/img/vector.svg'
import link from './../../assets/img/link.svg'
import cancel from './../../assets/img/cancel.svg'
import team from './../../assets/img/team.jpg'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {Link as LocalLink} from 'react-scroll'


type OwnToProps = {
    show?: boolean
    setShow?: any
    id?: number
    children?: any
    name?: string
    data?: localDataCase
}

const Example: FC<OwnToProps> = ({
                                     children,
                                     id,
                                     show,
                                     setShow, name,
                                     data
                                 }) => {

    const navigate = useNavigate()

    const [isCopied, setIsCopied] = useState(false)

    const closeModal = () => {
        setShow(false)
        navigate('/')
    }

    const url = window.location.href

    const copyTime = () => {
        setTimeout(() => {
            setIsCopied(true)
        }, 500)
    }

    return (
        <>
            {children}
            <Modal
                size={'xl'}
                dialogClassName={style.modalWindow}
                show={show}
                onHide={closeModal}
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Body>
                    <div className={style.navModal}>
                        <button onClick={closeModal}><img src={cancel} alt="cancel"/></button>
                        <CopyToClipboard text={url} onCopy={copyTime}>
                            {!isCopied ? <button><img src={link} alt="link"/></button> : <span>Copy!</span>}
                        </CopyToClipboard>
                    </div>
                    {data && <div className={style.modalContent}>
                        {/*<h1>{name}</h1>*/}
                        {/*<img className={style.teamImg} src={team} alt="img"/>*/}
                        <div>
                            <h2>About the Customer:</h2>
                            <p>{data.aboutCustomers}</p>
                        </div>
                        <div>
                            <h2>Challenge:</h2>
                            <p className={style.prediction}>{data.challengePrediction}</p>
                            <p>{data.challenge}</p>
                        </div>
                        <div>
                            <h2>Solution:</h2>
                            <p className={style.prediction}>{data.solutionPrediction}</p>
                            <p>{data.solution}</p>
                        </div>
                        <div>
                            <h2>Outcome:</h2>
                            <p className={style.prediction}>{data.outcomePrediction}</p>
                            <p>{data.outcome}</p>
                        </div>
                        {/*<div>*/}
                        {/*    <h2>See our other Case Studies</h2>*/}
                        {/*    <div className={style.learnMoreCases}>*/}
                        {/*        <Row>*/}
                        {/*            <Col xs={12} lg={2} md={2}>*/}
                        {/*                <img src={data.imgToCase} alt="img"/>*/}
                        {/*            </Col>*/}
                        {/*            <Col xs={12} lg={10} md={10}>*/}
                        {/*                <h2>{data.nameToCase}</h2>*/}
                        {/*            </Col>*/}
                        {/*        </Row>*/}
                        {/*        <div className={style.learnMoreCasesItem}>*/}
                        {/*            <Link onClick={() => setShow(false)} to={`/case/${data.linkToCase}`}>View Case study<img src={vector} alt="vector"/></Link>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className={style.questions}>
                            <h2>Still have questions? </h2>
                            <LocalLink onClick={closeModal} to="Contact us" smooth spy>Contact us<img src={vector} alt="vector"/></LocalLink>
                        </div>
                    </div>}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Example



