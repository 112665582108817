import React, { FC } from "react";
import style from "./ModelystBlock.module.css";
import { Row } from "react-bootstrap";
import ModelyBlockItem from "../../../Components/ModelystBlockItem";
import ModelystSecondBlockItem from "../../../Components/ModelystSecondBlockItem";
import { modelystItemInfo, modelystItemInfoSecond } from "../../../InputData";
import { useMediaQuery } from "../../../Hooks/mediaPhone";
import { AnimationOnScroll } from "react-animation-on-scroll";

const ModelystBlock: FC = () => {
  const isPagedWide = useMediaQuery("(max-width: 926px)");

  return (
    <section id="Modelyst" className={style.Modelyst}>
      <AnimationOnScroll
        animateIn={"animate__fadeInUp"}
        animateOnce
        duration={1.4}
      >
        <div className={style.infoBlock}>
          {isPagedWide && <h2 className={style.mobileName}>Modelyst</h2>}
          <h2>Custom Data Infrastructure</h2>
          <p>
            To a lot of problems that the world faces today, the solution lies
            in materials innovation. To accelerate the process, materials
            scientists are increasingly using data-driven techniques.
          </p>
          <p>
            To that end, Modelyst implements custom data infrastructure software
            so that your scientific data is used as effectively as possible. We
            organize R&D data into a database that is specifically designed for
            your use case so that it is easily accessible by researchers and
            data scientists.
          </p>
          <div className={style.infoBlockItem}>
            <Row>
              {modelystItemInfo.map((el) => (
                <ModelyBlockItem
                  key={el.id}
                  img={el.img}
                  name={el.name}
                  description={el.description}
                  btnSource={el.btnSource}
                />
              ))}
            </Row>
          </div>
        </div>
      </AnimationOnScroll>
      <div className={style.infoBlockDataManagement}>
        <AnimationOnScroll
          animateIn={"animate__fadeInUp"}
          animateOnce
          duration={1.4}
        >
          <h2 style={{ marginBottom: "40px" }}>
            Do you need a data management partner?
          </h2>
          <p style={{ marginBottom: "16px" }}>
            Are you experiencing any of the following problems?
          </p>
        </AnimationOnScroll>
        {modelystItemInfoSecond.map((el) => (
          <ModelystSecondBlockItem
            key={el.id}
            name={el.name}
            description={el.description}
          />
        ))}
      </div>
      {/*<AnimationOnScroll animateIn={'animate__fadeInUp'} animateOnce duration={1.4}>*/}
      {/*<div className={style.infoBlockUniqueEnvironment}>*/}
      {/*    <h2>Still unsure whether we fit into your unique environment?</h2>*/}
      {/*    <p>We believe every team’s needs are unique, and there’s no one-approach-fits-all solution when it comes*/}
      {/*        to data management. This <a href="#">tool</a> can help provide more insight into whether or not we*/}
      {/*        are a good fit.*/}
      {/*        Alternatively, set up a <a href="#">meeting with us</a>!</p>*/}
      {/*</div>*/}
      {/*</AnimationOnScroll>*/}
    </section>
  );
};

export default ModelystBlock;
