import React, { FC } from "react";
import style from "./Footer.module.css";
import miniLogo from "./../../assets/img/mini-logo.svg";
import { Link } from "react-router-dom";
import { Link as LinkScroll } from "react-scroll";

const Footer: FC = () => {
  let localRoutes = [
    "Modelyst",
    "What we do",
    "About us",
    "Case studies",
    "FAQ",
  ];

  // const routes = ['privacy-policy', 'terms-of-use']
  const routes: string[] = [];

  return (
    <footer className={style.footer}>
      <div className={style.footerItem}>
        <div className={style.footerLogo}>
          <img src={miniLogo} alt="mini-logo" />
        </div>
        <div className={style.footerNav}>
          {localRoutes.map((el, index) => (
            <LinkScroll
              key={index}
              to={el}
              className={style.linkFooter}
              activeClass="activeFooter"
              smooth
              spy
            >
              {el}
            </LinkScroll>
          ))}
        </div>
      </div>
      <div className={style.footerAfter}>
        <div>
          <span>{`Copyright © Modelyst ${new Date().getFullYear()} `}</span>
        </div>
        <div>
          {routes.map((el, index) => (
            <Link key={index} to={`/${el}`}>
              {el.replace("-", " ")}
            </Link>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
