import React, {FC} from 'react';
import style from './ModelyBlockItem.module.css'
import {Col} from "react-bootstrap";
import vector from './../../assets/img/vector.svg'

type OwnToProps = {
    img: string
    name: string
    description: string
    btnSource: boolean
}

const ModelyBlockItem: FC<OwnToProps> = ({img, name, description, btnSource}) => {
    return (
        <Col xs={12} md={6} lg={6}>
            <div className={style.block}>
                <div className={style.blockItem}>
                    <div><img src={img} alt="img"/></div>
                    <p className={style.blockItemName}>{name}</p>
                    {/*{btnSource ? <div></div> : <p className={style.blockItemDescription}>{description}</p>}*/}
                </div>
                {btnSource && <div className={style.blockSources}><a href="#">Source<img src={vector} alt="vector"/></a></div>}
            </div>
        </Col>
    );
};

export default ModelyBlockItem;
