import React, {FC, useEffect, useState} from 'react';
import logo from "../../assets/img/logo.svg";
import {Link} from "react-scroll";
import style from './MobileMenu.module.css'
import openImg from './../../assets/img/open.svg'
import closeImg from './../../assets/img/close.svg'

const MobileMenu:FC = () => {

    const [open, setOpen] = useState(false)

    const [scroll, setScroll] = React.useState(0);

    const handleScroll = () => {
        setScroll(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const localRoutes: string[] = ['Modelyst','What we do', 'About us', 'Case studies', 'FAQ']

    const handleToggle = () => {
        setOpen(!open)
    }

    return (
        <>
            <div className={style.mobileMenu + ' ' + (scroll > 1 && style.fixedMenu)}>
                <div style={{paddingTop:"16px"}} className={style.mobileMenuItem}><Link to="mainBlock" activeClass="activeMobileMenu" smooth spy><img src={logo}
                                                                                                                    alt="logo"/></Link></div>
                <div className={style.mobileNavigation}>
                    <button onClick={handleToggle}><img src={!open ? openImg : closeImg} alt="img"/></button>
                </div>
            </div>
            {open && <div className={style.mobileMenuLink}>
                <div className={style.mobileMenuLinkItem}>
                    {localRoutes.map((el, index) => <Link key={index} to={el} onClick={() => setOpen(false)} activeClass="activeMobileMenu" duration={300} smooth spy>{el}</Link>)}
                </div>
                <div className={style.mobileMenuLinkContact}><Link  to={'Contact us'} onClick={() => setOpen(false)} activeClass="active" duration={300} smooth spy>Contact us</Link></div>
            </div>}
        </>

    );
};

export default MobileMenu;
