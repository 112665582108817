import React, {FC} from 'react';
import {Col} from "react-bootstrap";
import style from './PeopleCard.module.css'

type OwnToProps = {
    name: string
    avatar: string
    description: string
}

const PeopleCard:FC<OwnToProps> = ({name, avatar, description}) => {
    return (
        <Col xs={12} lg={6} md={6}>
            <div className={style.peopleCardItem}>
                <img src={avatar} alt="avatar"/>
                <h3>{name}</h3>
                <p>{description}</p>
            </div>
        </Col>
    );
};

export default PeopleCard;
