import React, {FC, useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import style from './Form.module.css'
import {logDOM} from "@testing-library/react";

const Form: FC = () => {

    const [text, setText] = useState('')

    const form: any = useRef();

    const sendBtn = (event: any) => {
        event.preventDefault();
        const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
        let valid = EMAIL_REGEXP.test(form.current.email.value)
        if (!valid){
            setText('Email error!')
        } else {
            emailjs.sendForm('modelyst_gmail', 'template_9wobqjj', form.current, 'YuVx2R7-NfXA1J-BG')
                .then((result: any) => {
                    setText('Message is sent!')
                    setTimeout(() => {
                        setText('')
                    }, 5000)
                }, (error: any) => {
                    setText('Error!')
                    setTimeout(() => {
                        setText('')
                    }, 2000)
                });
        }

    }

    return (
        <>
            <form className={style.form} ref={form} onSubmit={sendBtn}>
                <div className={style.formName}>
                    <input type="text" minLength={2} name="name" placeholder="Name"/>
                </div>
                <div className={style.formEmail}>
                    <input type="text" name="email" placeholder="E-mail"/>
                </div>
                <div className={style.formMessage}>
                    <textarea name="message" minLength={4} placeholder="Message"/>
                </div>
                <div className={style.formBtn}>
                    <button>Send Message</button>
                </div>
            </form>
            {text && <div className={style.messageInfo}>{text}</div>}
        </>

    );
};

export default Form;
