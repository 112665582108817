import React, {FC} from 'react';
import style from './Quote.module.css'
import {Col, Row} from "react-bootstrap";

type OwnToProps = {
    avatar: string
    name: string
    role: string
    description: string
    lightMode: boolean
}

const Quote: FC<OwnToProps> = ({avatar, name, role, description, lightMode}) => {
    return (
        <div className={lightMode ? style.quoteLight : style.quote}>
            <Row>
                <Col md={3} lg={3}>
                    <div className={style.name}>
                        <img src={avatar} alt="avatar"/>
                        <p>{name}<br/><span>{role}</span></p>
                    </div>
                </Col>
                <Col md={9} lg={9}>
                    <div className={style.quoteItem}>
                        <p>{description}</p>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Quote;
