import React, { FC } from "react";
import { Link } from "react-scroll";
import { Link as RouteLink } from "react-router-dom";
import style from "./HeaderNavBar.module.css";
import logo from "./../../assets/img/logo.svg";

export const localRoutes: string[] = [
  "What we do",
  "About us",
  "Case studies",
  "FAQ",
  "Contact us",
];

const HeaderNavbar: FC = () => {
  return (
    <div className={style.headerNavbar}>
      <div className={style.headerNavbarLogo}>
        <Link
          to="mainBlock"
          activeClass="active-modelyst"
          smooth
          spy
          duration={300}
        >
          <img src={logo} alt="logo" />
        </Link>
      </div>
      <div className={style.headerNavbarNavigation}>
        {localRoutes.map((el, index) => (
          <Link
            key={index}
            to={el}
            activeClass="active"
            smooth
            spy
            duration={300}
          >
            {el}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default HeaderNavbar;
