import React, { FC } from "react";
import style from "./FAQ.module.css";
import Tabs from "../../../Components/Tabs";
import { useMediaQuery } from "../../../Hooks/mediaPhone";
import { AnimationOnScroll } from "react-animation-on-scroll";

const FAQBlock: FC = () => {
  const isPagedWide = useMediaQuery("(max-width: 926px)");

  return (
    <section id="FAQ" className={style.faqBlock}>
      <AnimationOnScroll
        animateIn={"animate__fadeInUp"}
        animateOnce
        duration={1.4}
      >
        {isPagedWide && <h2 className={style.mobileName}>FAQ</h2>}
        <h2>Choose your Role:</h2>
        <Tabs />
      </AnimationOnScroll>
    </section>
  );
};

export default FAQBlock;
