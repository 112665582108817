import React, { FC } from "react";
import { dataCases } from "../../../InputData";
import CaseStudiosItemBlock from "../../../Components/CaseStudiosItemBlock";
import style from "./CaseStudiosBlock.module.css";
import { Row } from "react-bootstrap";
import { useMediaQuery } from "../../../Hooks/mediaPhone";
import { AnimationOnScroll } from "react-animation-on-scroll";

const CaseStudiosBlock: FC = () => {
  const isPagedWide = useMediaQuery("(max-width: 926px)");

  return (
    <section id="Case studies" className={style.caseStudies}>
      <AnimationOnScroll
        animateIn={"animate__slideInUp"}
        animateOnce
        duration={0.3}
        delay={0.15}
      >
        {isPagedWide && <h2 className={style.mobileName}>Case studies</h2>}
        <Row>
          {dataCases.map((el, index) => (
            <CaseStudiosItemBlock
              key={index}
              data={el.data}
              img={el.img}
              id={el.id}
              name={el.name}
            />
          ))}
        </Row>
      </AnimationOnScroll>
    </section>
  );
};

export default CaseStudiosBlock;
