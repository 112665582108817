import React, {FC} from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import style from './ModelystSecondBlockItem.module.css'

type OwnToProps = {
    name: string
    description: string
}

const ModelystSecondBlockItem:FC<OwnToProps> = ({name, description}) => {
    return (
        <AnimationOnScroll animateIn={'animate__fadeInUp'} animateOnce duration={1.4}>
        <div className={style.block}>
            <h3>{name}</h3>
            <p>{description}</p>
        </div>
        </AnimationOnScroll>
    );
};

export default ModelystSecondBlockItem;
