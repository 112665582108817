import clock from "../assets/img/clock.svg";
import money from "../assets/img/money.svg";
import card0 from "../assets/img/PastClientLight/card0.svg";
import card1 from "../assets/img/PastClient/card1.png";
import card2 from "../assets/img/PastClient/card2.png";
import card3 from "../assets/img/PastClient/card3.png";
import card4 from "../assets/img/PastClient/card4.png";
import card5 from "../assets/img/PastClient/card5.png";
import card6 from "../assets/img/PastClient/card6.png";
import card7 from "../assets/img/PastClient/card7.png";
import card8 from "../assets/img/PastClient/card8.png";
import card9 from "../assets/img/PastClient/card9.png";
import card1Light from "../assets/img/PastClientLight/card1.svg";
import card2Light from "../assets/img/PastClientLight/card2.svg";
import card3Light from "../assets/img/PastClientLight/card3.svg";
import card4Light from "../assets/img/PastClientLight/card4.svg";
import card5Light from "../assets/img/PastClientLight/card5.svg";
import card6Light from "../assets/img/PastClientLight/card6.svg";
import card7Light from "../assets/img/PastClientLight/card7.svg";
import card8Light from "../assets/img/PastClientLight/card8.svg";
import card9Light from "../assets/img/PastClientLight/card9.svg";
import card10Light from "../assets/img/PastClientLight/card10.svg";
import Michael from "../assets/img/people/michael-statt.png";
import Brian from "../assets/img/people/brian-rohr.png";
import diagram from "../assets/img/diagram.svg";
import pc from "../assets/img/pc.svg";

import {
  Client,
  DataCases,
  Models,
  ModelystInfo,
  ModelystInfoSecond,
  OurStory,
  PeopleCard,
} from "../Types";

export const modelystItemInfo: ModelystInfo[] = [
  {
    id: 1,
    img: clock,
    name: "Scientists spend 2x more time on science, not data wrangling",
    description: "",
    btnSource: false,
  },
  {
    id: 2,
    img: money,
    name: "Up to 50% reduction in costs spent on menial data organization tasks",
    description:
      "* Benchmarking the acceleration of materials discovery by sequential learning - Chemical Science (RSC Publishing) DOI:10.1039/C9SC05999G",
    btnSource: false,
  },
];

export const modelystItemInfoSecond: ModelystInfoSecond[] = [
  {
    id: 1,
    name: "Out-of-the-box solution doesn’t meet your needs",
    description:
      "Scientific research is complex, and each research group has unique needs. Out-of-the-box solutions often fail to meet 100% of the needs of the research group, and the resulting workarounds usually lead to the use of several systems that don’t talk to each other well. Don’t try to adapt your workflow to someone else’s software; the software should be adapted to your workflow.",
  },
  {
    id: 2,
    name: "Hiring a full in-house data engineering team would cost too much",
    description:
      "Hiring several data engineers for to each R&D team is very costly. We have already implemented the baseline infrastructure code that is common to almost all use cases, and our software is set up so that we can quickly implement the customizations that your team needs. Why pay engineers to do years of infrastructure work that we have already done?",
  },
  {
    id: 3,
    name: "Researchers waste time on data wrangling",
    description:
      "Due to the clear drawbacks of out-of-the-box solutions and hiring teams of data engineers, it is common for data wrangling work to fall on researchers. This usually leads to unhappy researchers, a lack of shared data infrastructure, and missed opportunities for data-driven research and decision making that is silently costing the team very large amounts of money.",
  },
];

export const modelystHelpAutomation: Client[] = [
  {
    id: 1,
    img: card0,
    imgLight: card0,
    name: "New data automatically flows into your database",
  },
  {
    id: 2,
    img: card2,
    imgLight: card2Light,
    name: "Your custom data analysis is done automatically",
  },
  {
    id: 3,
    img: card5,
    imgLight: card5Light,
    name: "Anomaly detection ensures data quality",
  },
];

export const modelystHelpMachineLearning: Client[] = [
  {
    id: 1,
    img: card4,
    imgLight: card4Light,
    name: "Already-trained ML models are deployed into the data pipeline",
  },
  {
    id: 2,
    img: card9,
    imgLight: card9Light,
    name: "Optimal experiments are suggested using bayesian optimization",
  },
  {
    id: 3,
    img: card10Light,
    imgLight: card10Light,
    name: "Datasets for ML are only a query away",
  },
];

export const modelystHelpUserInterfaces: Client[] = [
  {
    id: 1,
    img: card1,
    imgLight: card1Light,
    name: "Upload data with a custom UI that makes sense to your scientists",
  },
  {
    id: 2,
    img: card8,
    imgLight: card8Light,
    name: "Easily view the history of every sample in your lab",
  },
  {
    id: 3,
    img: card6Light,
    imgLight: card6Light,
    name: "Explore data with custom visualizations tailored to your needs",
  },
];

export const pastClient: Client[] = [
  {
    id: 1,
    img: card1,
    imgLight: card1Light,
    name: "Create custom user interface for data and metadata ingestion parse raw data files and extract metadata",
  },
  {
    id: 2,
    img: card2,
    imgLight: card2Light,
    name: "Automate custom data processing and analysis steps",
  },
  {
    id: 3,
    img: card3,
    imgLight: card3Light,
    name: "Make it easy for data scientists to assemble clean datasets for machine learning projects",
  },
  {
    id: 4,
    img: card4,
    imgLight: card4Light,
    name: "Deploy already-trained machine learning models into the data pipeline",
  },
  {
    id: 5,
    img: card5,
    imgLight: card5Light,
    name: "Make use of anomaly detection techniques to assess data quality",
  },
  {
    id: 6,
    img: card6,
    imgLight: card6Light,
    name: "Look at data through custom visualizations that are tailored to their use case",
  },
  {
    id: 7,
    img: card7,
    imgLight: card7Light,
    name: "Make it easy for researchers to look up what experiments have already been done",
  },
  {
    id: 8,
    img: card8,
    imgLight: card8Light,
    name: "View the history of a sample including all of the experiments it has gone through and what data was produced at each step",
  },
  {
    id: 9,
    img: card9,
    imgLight: card9Light,
    name: "Use machine learning and bayesian optimization to suggest the statistically optimal next experiment and thereby drastically accelerate material or process optimization",
  },
];

export const engagementModels: Models[] = [
  {
    id: 1,
    name: "Platform only",
    description:
      "For teams who would like to use our platform on their own, the platform is open-source, and we are available to assist you and can engage using small, hourly support contracts.",
  },
  {
    id: 2,
    name: "Limited Period Consulting for platform deployment",
    description:
      "For teams who need a burst of data engineering effort to get their initial data infrastructure up and running, we engage as consultants for a period of anything starting from 3 months",
  },
];

export const ourStory: OurStory[] = [
  {
    id: 1,
    date: "2016",
    description:
      "Mike and Brian start working together on computational materials science and ML projects during their PhDs at Stanford University.",
  },
  {
    id: 2,
    date: "2017",
    description:
      "They build a piece of software that enables them to create data pipelines using a flexible and maintainable framework rather than a set of one-off python scripts. Schema changes were no longer daunting tasks.",
  },
  {
    id: 3,
    date: "2018",
    description:
      "Mike and Brian discover that many materials science R&D groups struggle with the challenging problem of organizing scientific data in a way that allows for easy access and use in machine learning.",
  },
  {
    id: 4,
    date: "2019",
    description:
      "Modelyst founded. Modelyst begins working with researchers in at one of the world’s largest auto manufacturers to implement data pipelines and databases on their behalf. The scientists love the service because it enables them to stop spending time organizing data and instead spend their time on thinking about the science and machine learning projects.",
  },
  {
    id: 5,
    date: "2020",
    description:
      "Modelyst expands into the academic space and starts working with its first university research group.",
  },
  {
    id: 6,
    date: "2021",
    description:
      "Modelyst begins creating data infrastructure for one of the US National Labs.",
  },
  {
    id: 7,
    date: "2022",
    description:
      "Modelyst begins creating front-end tools to improve data accessibility.",
  },
];

export const peopleCard: PeopleCard[] = [
  {
    id: 1,
    name: "Michael Statt, Ph.D., Stanford University",
    avatar: Michael,
    description:
      "Mike’s PhD focused on applying computational methods to fertilizer production, and during his PhD, he became an expert in managing the large amounts of data that the group’s simulations produced. In his free time, Mike enjoys playing the piano.",
  },
  {
    id: 2,
    name: "Brian Rohr, Ph.D., Stanford University",
    avatar: Brian,
    description:
      "Brian’s PhD focused on applying physics- and ML-based computational methods to problems in catalysis. In his spare time, he enjoys doing almost anything outside, especially skiing, surfing, and mountain biking.",
  },
];

export const dataCases: DataCases = [
  {
    id: 1,
    name: "Research Group Within a Top Engineering University",
    img: diagram,
    data: {
      aboutCustomers:
        "R&D group within a large automobile manufacturing company",
      challenge:
        "The data pipelines were maintained by scientists, not data engineers. Furthermore, because it is a university setting, the turnover on the team was high, and continuity in the data infrastructure was difficult to achieve. The client’s vast amounts of experimental data was so difficult to access and aggregate that it was not able to be used for any of the client’s desired machine learning projects.",
      challengePrediction:
        "Lack of data infrastructure in absence of data engineers",
      solution:
        "Modelyst became the data engineering team for this research group and meets weekly with the scientists to make sure their needs are always met.",
      solutionPrediction: "Modelyst becomes the data engineering team",
      outcome:
        "As researchers come and go, the data is always ready for new projects. Researchers are spending time on what they are experts in, not on data management, and the client pays us less than the cost of even one full-time data engineer. The client has used the data for the machine learning projects they originally wanted to do.",
      outcomePrediction: "Data readily available for new projects",
      linkToCase: 2,
      nameToCase:
        "Customer gets R&D data managed on-the-go without any in-house data engineer.",
      imgToCase: pc,
    },
    // },
    // {
    //     id: 2,
    //     name: 'Customer gets R&D data managed on-the-go without any in-house data engineer.',
    //     img: pc,
    //     data: {
    //         aboutCustomers: 'Research Group Within a Major Engineering University',
    //         challenge: 'The data pipelines were maintained by people who were not experts in data engineering. Furthermore, because it is a university setting, the turnover on the team was high, and continuity in the data infrastructure was difficult to achieve.',
    //         challengePrediction: 'Lack of data infrastructure in absence of data engineers',
    //         solution: 'Modelyst became the data engineering team for this research group and meets weekly with the scientists to make sure their needs are always met.',
    //         solutionPrediction: 'Modelyst becomes the data engineering team\n',
    //         outcome: 'As researchers come and go, the data is always ready for new projects. Researchers are spending time on what they are experts in, not data management, and the client pays us less than the cost of even one full-time data engineer.\n',
    //         outcomePrediction: 'Data readily available for new projects\n',
    //         linkToCase: 1,
    //         nameToCase: 'Customer got a database ready for use in ML and other analyses up and running in half the time and at ~¼ of the cost compared to hiring in-house data engineers',
    //         imgToCase: diagram
    //     }
  },
];
